import axios from "axios"
import { makeAutoObservable, runInAction } from "mobx"

export const BASE_URL =
  process.env.NEXT_PUBLIC_API_URL || "https://clutchaudience.com"

export class ConfigStore {
  _store = {
    darkMode: true,
    user: {
      user_id: "",
      username: "",
      loggedIn: false,
      user_type: "", // reviewer || artist
      roles: [],
      userInsight: {},
      hasConnectedStripeAccount: false,
      hasConnectedStripeAccount: false,
      // Do we need this?
      hasUserInsights: false,
      new: false,
      numReqSessions: 4,
    },
  }

  constructor(initialData = {}) {
    makeAutoObservable(this)
    this._store = {
      ...this._store,
      ...initialData,
    }
  }

  // Action to update user data
  setUser(userData) {
    runInAction(() => {
      this._store.user = {
        ...this._store.user,
        ...userData, // Spreads userData over the existing user data
        user_type: userData.accountType,
        username: userData.username,
        loggedIn: !!userData, // Sets loggedIn based on the existence of userData
      }
    })
  }

  /**
   * ---------------------------------------
   * Getters
   * ---------------------------------------
   */

  get store() {
    return this._store
  }

  /**
   * ---------------------------------------
   * Actions
   * ---------------------------------------
   */

  changeTheme() {
    // Make Network call to store this configuration for user, wrap in try catch
    runInAction(() => {
      this._store.darkMode = !this._store.darkMode
    })
  }

  updateUser(user) {
    // Update user information in the store
    this._store.user = { ...this._store.user, ...user }
  }

  async resetStore() {
    this._store.user = {
      loggedIn: false,
      user_type: "", // reviewer || artist
      roles: [],
      user_id: "",
      username: "",
    }
  }

  async logout() {
    const api = axios.create({
      baseURL: BASE_URL,
    })

    const response = await api.post("/api/users/signout", {
      withCredentials: true,
      headers: {
        credentials: "include",
      },
    })

    runInAction(() => {
      this._store.user = {
        loggedIn: false,
        new: false,
        user_type: "",
        roles: [],
        user_id: "",
        username: "",
      }
    })

    // document.cookie = `express:sess=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }

  async login({ email, password }) {
    const api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      withCredentials: true,
    })

    try {
      const response = await api.post(
        "/api/users/signin",
        {
          email: email,
          password: password,
        },
        {
          headers: {
            "Access-Control-Allow-Credentials": "true",
            "Content-Type": "application/json",
          },
        },
      )

      // if bares well set store to config props
      runInAction(() => {
        this._store.darkMode = true
        this._store.user = {
          loggedIn: true,
          new: false,
          user_type: response.data.user.accountType,
          roles: [response.data.user.accountType],
          user_id: response.data.user.id,
          username: response.data.user.username,
        }
      })

      try {
        const { data: userInsightData } = await api.get(
          `/api/userInsights/user/${response.data.user.id}`,
        )

        // if bares well set store to config props
        runInAction(() => {
          this._store.darkMode = true
          this._store.user = {
            ...this._store.user,
            userInsight: userInsightData,
            userInsight: userInsightData,
          }
        })
      } catch {
        console.error("Error getting user insight data:", error)
        return "Error getting user insight data"
      }

      if (response.data?.user?.accountType === "reviewer") {
        try {
          // Fetch connected account status
          const { data: accountStatusData } = await api.get(
            `/api/earnings/account-status`,
          )

          const hasConnectedStripeAccount =
            accountStatusData.accountStatus?.detailsSubmitted &&
            accountStatusData.accountStatus?.payoutsEnabled

          // Check if connectedAccountId exists and update store
          if (hasConnectedStripeAccount) {
            runInAction(() => {
              this._store.user = {
                ...this._store.user,
                hasConnectedStripeAccount: hasConnectedStripeAccount,
              }
            })
          }
        } catch (error) {
          console.log("Error getting account status data:", error)
        }
      }

      // Send Data
      return response.data
    } catch (err) {
      // console.error("Error during login process:", err)
      const resp = err.response.data

      if (resp?.errors?.length) {
        const error = resp.errors[0]
        return {
          error: true,
          message: error.message,
        }
      } else {
        return {
          error: true,
          message: resp[0].message || "An unexpected error occurred",
        }
      }
    }
  }

  async register(fields) {
    try {
      const api = axios.create({
        baseURL: BASE_URL,
        withCredentials: true,
      })

      const userConfig = {
        email: fields.email,
        password: fields.password,
        accountType: fields.account_type,
        phoneNumber: fields.phone_number,
        firstName: fields.first_name,
        lastName: fields.last_name,
        instagramUsername: "",
        twitterUsername: "",
        twitchUsername: "",
        youtubeUsername: "",
      };

      if(fields.referral) {
        userConfig['referral'] = fields.referral 
      }
      
      // 1. Hit endpoint to register,
      const response = await api.post(
        "/api/users/signup",
        userConfig,
        {
          headers: {
            credentials: "include",
            "Content-Type": "application/json",
          },
        },
      )

      // if bares well set store to config props
      runInAction(() => {
        this._store.darkMode = true
        this._store.user = {
          loggedIn: true,
          new: true,
          user_type: response.data.user.accountType,
          roles: [response.data.user.accountType],
          user_id: response.data.user.id,
          username: response.data.user.username,
        }
      })
      return response.data
    } catch (err) {
      // Extract response if available
      const resp = err.response?.data
      console.error("Registration Error: ", err)

      if (resp?.[0]?.message === "Email in use") {
        return {
          error: true,
          message: "This email is already in use.",
        }
      } else if (resp?.errors?.length) {
        const errorMessage = resp.errors.map(e => e.message).join("; ")
        return {
          error: true,
          message: errorMessage || "An unexpected error occurred.",
        }
      } else {
        // Handle unexpected error format or network issues
        return {
          error: true,
          message: err.message || "A network error occurred. Please try again.",
        }
      }
    }
  }

  async logout(req, res) {
    const api = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
    })

    try {
      // Make an API request to the logout endpoint
      await api.post("/api/users/signout")

      // Update the user store after successful logout
      runInAction(() => {
        this._store.user = {
          loggedIn: false,
          new: false,
          user_type: "",
          roles: [],
          user_id: "",
          username: "",
          userInsight: {},
        }
      })
    } catch (error) {
      console.error("Logout failed", error)
    }
  }

  async setUserInsight(insightData) {
    runInAction(() => {
      this._store.user = {
        ...this._store.user,
        userInsight: insightData,
      }
    })
  }

  async fetchStripeAccountStatus() {
    try {
      const api = axios.create({
        baseURL: BASE_URL,
        withCredentials: true,
      })

      const { data: accountStatusData } = await api.get(
        "/api/earnings/account-status",
      )

      const hasConnectedStripeAccount =
        accountStatusData.accountStatus?.detailsSubmitted &&
        accountStatusData.accountStatus?.payoutsEnabled

      // Update config.store
      runInAction(() => {
        this._store.user.hasConnectedStripeAccount = hasConnectedStripeAccount
      })

      return hasConnectedStripeAccount
    } catch (error) {
      console.error("Error fetching account status:", error)
      runInAction(() => {
        this._store.user.hasConnectedStripeAccount = false
      })
      return false // or handle this case however you want
    }
  }
}
